export function useMoralis() {
  // Moralis Initialization
  let Moralis;
  if (typeof window !== `undefined`) {
    Moralis = require("moralis");
    // Moralis.initialize(process.env.GATSBY_MORALIS_APPLICATION_ID);
    Moralis.initialize("fGwRexGfCQ4Te2lRZMQKKI6JkmRFko0ADVTrCsO7");
    // Moralis.serverURL = process.env.GATSBY_MORALIS_SERVER_URL;
    Moralis.serverURL = 'https://osraoqzvgxgm.moralis.io:2053/server';
  }
  return { Moralis };
}