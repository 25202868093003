import { navigate } from "gatsby-link";
import { useMoralis } from "./useMoralis";

export function useAuth() {
  const { Moralis } = useMoralis();
  return {
    login: async () => {
      try {
        const user = await Moralis?.Web3.authenticate();
        const web3 = await Moralis?.Web3.enable();
        // const contract = new web3.eth.Contract(contractAbi, contractAddress);
        navigate("/");
      } catch (e) {
        console.error(e.message, e);
      }
    },

    logout: async () => {
      try {
        await Moralis?.User.logOut();
        navigate("/login");
      } catch (e) {
        console.error(e.message, e);
      }
    },

    listenToUpdates: async () => {
      let query = new Moralis.Query("EthTransactions");
      let subscription = await query.subscribe();

      subscription.on("create",(object)=>{
        console.log("new transaction!");
        console.log(object);
      });
    },

    currentUser: () => {
      return Moralis?.User.current();
    },

    transQuery: async () => {
      const user = Moralis?.User.current();
      const transQuery = new Moralis.Query("EthTransactions");
      transQuery.equalTo("from_address", user.get("ethAddress"));

      try {
        const results = await transQuery.find();
        return results;
      } catch (e) {
        console.error(e.message, e);
      }
    },

  };
}